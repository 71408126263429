<template>
  <div class="p-login-modal">
    <div class="p-login-container">
      <!-- <div class="p-login-header">
        <img src="@/assets/logo.png" alt="logo" width="32" height="32" />
        <span><i>Prismlab</i></span>
      </div> -->
      <div class="p-login-msg">{{ authMsg }}</div>
      <div style="text-align: center">
        <el-button type="primary" @click="login">请登录</el-button>
      </div>
      <router-view name="Callback" />
    </div>
  </div>
</template>

<script>
import Oidc from "oidc-client";

export default {
  data() {
    return {
      /**oidc配置 */
      config: this.$store.state.authConfig,
      manager: undefined,
      authMsg: "您尚未登录",
    };
  },
  methods: {
    login() {
      this.manager.signinRedirect().catch(() => {
        this.$notify({
          type: "error",
          title: "错误",
          message: "服务器尚未连接",
          duration: 0,
        });
      });
    },
  },
  mounted() {
    console.log("in login");
    localStorage.clear();
    let config = JSON.parse(JSON.stringify(this.config));
    config.userStore = new Oidc.WebStorageStateStore({ store: localStorage });
    this.manager = new Oidc.UserManager(config);
    this.manager.removeUser();
  },
};
</script>

<style lang="scss" scoped>
.p-login-modal {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9998;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 1);
  .p-login-container {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 9999;
    width: 500px;
    padding: 2rem;
    // border: 1px solid #dcdfe6;
    border-radius: 3px;
    transform: translate(-50%, -50%);
    background-color: #fff;
    box-sizing: border-box;
    .p-login-msg {
      margin-bottom: 1.8rem;
      text-align: center;
    }
    .p-login-header {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.8rem;
      font-size: 1.6rem;
      font-weight: 700;
      img {
        margin-right: 0.5rem;
      }
    }
  }
}
</style>