<template>
  <div>
    <p-login></p-login>
  </div>
</template>

<script>
import pLogin from "@/components/Login";
export default {
  components: {
    pLogin,
  },
};
</script>